
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { required, minLength, maxLength, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import icons from '@/views/components/icon/index'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonSecondary from '@/views/components/button/ButtonSecondary.vue'
import FormInput from '@/views/components/form/FormInput.vue'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import { useStore } from 'vuex'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import { IOrderForm } from './formModel'

export default defineComponent({
  components: {
    Step1,
    Step2,
    Step3,
    ButtonPrimary,
    ButtonSecondary,
    FormInput,
    HjpConfirmationModal,
    ...icons
  },
  setup() {
    const store = useStore()
    const activeStep = ref(0)
    const defaultOrderForm: IOrderForm = {
      contractRoute: {
        id: '',
        contractId: '',
        contractName: '',
        name: '',
        originName: '',
        destinationName: ''
      },
      sender: {
        name: 'John Doe',
        phone: '81223320644',
        address: 'Jl.Mastrip No.5A, Kalirungkut, Tanjungsari, Kota Surabaya , Jawa Timur - 74657'
      },
      recipient: {
        name: 'Carmella Obelix',
        phone: '81283764533',
        address: 'Jl.Letkol Suwarno 8A, Antapani Wetan, Antapani, Kota Bandung, Jawa Barat - 34534'
      },
      packageDetails: {
        receiptCode: 'MIISI.PAKET.TA.23.10.0000145',
        shippingType: 'AA',
        orderDate: new Date().toISOString().split('T')[0],
        totalPackages: 0,
        totalVolume: 0,
        totalWeight: 0,
        totalPrice: 0,
        notes: 'Barang sudah sesuai dengan pesanan'
      }
    }

    const formData = ref<IOrderForm>({ ...defaultOrderForm })

    const rules = {
      contractRoute: { id: { required } },
      sender: {
        name: { required },
        phone: { required, minLength: minLength(10), maxLength: maxLength(15) },
        address: { required },
      },
      recipient: {
        name: { required },
        phone: { required, minLength: minLength(10), maxLength: maxLength(15) },
        address: { required },
      },
      packageDetails: {
        receiptCode: { required },
        shippingType: { required },
        orderDate: { required },
        totalPackages: { required, numeric },
        totalVolume: { required, numeric },
        totalWeight: { required, numeric },
        totalPrice: { required, numeric },
        notes: { required },
      },
    }

    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/route-save.svg'),
      title: 'Konfirmasi Pembuatan Order',
      subTitle: '',
      btnSubmitTitle: 'Buat Order',
    })

    const v$ = useVuelidate(rules, formData)

    const resetForm = () => {
      formData.value = { ...defaultOrderForm }
    }

    const environment = computed(() => store.state.switchEnv)

    const getClassByEnv = (isActive: boolean, stagingClass: string, productionClass: string, defaultClass: string) => {
      if (isActive) {
        return environment.value === 'staging' ? stagingClass : productionClass
      }
      return defaultClass
    }

    const stepClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'bg-secondary-100', 'bg-primary-100', 'bg-gray-200'),
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-500', 'text-gray-400')
    ]

    const lineClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'bg-secondary-500', 'bg-primary-700', 'bg-gray-300')
    ]

    const labelClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-500', 'text-gray-500'),
      'font-semibold'
    ]

    const subLabelClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-100', 'text-gray-400'),
      'text-sm'
    ]

    const validateStep = (fields: string[]) => {
      fields.forEach((field) => v$.value[field].$touch())
      return fields.every((field) => !v$.value[field].$invalid)
    }

    const nextStep = () => {
      const currentStep = activeStep.value
      if (currentStep === 0 && validateStep(['sender', 'recipient', 'contractRoute'])) {
        activeStep.value++
      } else if (currentStep === 1 && validateStep(['packageDetails'])) {
        activeStep.value++
      }
    }

    const prevStep = () => {
      if (activeStep.value > 0) activeStep.value--
    }

    const setStep = (step: number) => {
      const currentStep = activeStep.value
      if (step > currentStep) {
        if (currentStep === 0 && !validateStep(['sender', 'recipient', 'contractRoute'])) {
          return 
        }
        if (currentStep === 1 && !validateStep(['packageDetails'])) {
          return 
        }
      }
      if (step <= currentStep + 1) {
        activeStep.value = step
      }
    }

    const submitOrder = () => {
      console.log(formData.value)
      if ((activeStep.value === 0 && !validateStep(['sender', 'recipient'])) 
          || (activeStep.value === 1 && !validateStep(['packageDetails']))) {
        return
      }
      console.log('passed')
      modalConfirmation.subTitle = `Apakah Anda yakin ingin membuat order untuk pengiriman <br>dari <strong>${formData.value.contractRoute.originName}</strong> ke <strong>${formData.value.contractRoute.destinationName}</strong> ?<br>Pastikan semua informasi sudah benar.`
      modalConfirmation.show = true
    }

    const hideModalConfirmation = () => {
      modalConfirmation.show = false
    }

    const doSubmitConfirm = async () => {   
      console.log('doSubmitConfirm')
    } 

    onMounted(() => {
      resetForm()
    })

    return {
      formData,
      activeStep,
      nextStep,
      prevStep,
      setStep,
      submitOrder,
      modalConfirmation,
      hideModalConfirmation,
      doSubmitConfirm,
      v$,
      icons,
      stepClass,
      lineClass,
      labelClass,
      subLabelClass,
      environment
    }
  },
})
