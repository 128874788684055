
import Sidebar from 'primevue/sidebar'
import {
  useStore
} from 'vuex'
import {
  ref,
  computed,
  defineComponent,
  onMounted
} from 'vue'
import { leftMenuProduction, leftMenuStaging } from '@/data/local/json/leftmenu'
import DropdownListMenu from '@/views/shared-components/DropdownListMenu.vue'
import ButtonWhite from '@/views/components/button/ButtonWhite.vue'

export default defineComponent({
  name: 'LeftPanel',
  components: {
    Sidebar,
    DropdownListMenu,
    ButtonWhite,
  },
  data() {
    return {
      selectedEnvironment: localStorage.getItem('environment'),
      items: ref(localStorage.getItem('environment') === 'production' ? leftMenuProduction() : leftMenuStaging())
    }
  },
  watch: {
    selectedEnvironment(val) {
      localStorage.setItem('switchEnv', val)
      this.items = val === 'production' ? leftMenuProduction() : leftMenuStaging()
      this.store.dispatch('setEnvironment', val)
    },
  },
  methods: {
    handleChange(e: any) {
      localStorage.setItem('environment', e.target.value)
      this.selectedEnvironment = e.target.value
      this.items = e.target.value === 'production' ? leftMenuProduction() : leftMenuStaging()
      this.store.dispatch('setEnvironment', e.target.value)
      this.$router.push({
        name: 'dashboard'
      })
    }
  },
  setup() {
    const store = useStore()
    const env = process.env
    const version = localStorage.getItem('version')
    const isLoaded = ref(false)
    store.dispatch('setEnvironment', localStorage.getItem('environment'))

    const activeSlider = computed({
      get: () => store.state.toggleMenu,
      set: (val) => {
        store.dispatch('setToggleMenu', val)
      }
    })
    const isMobile = ref(window.innerWidth > 1024 ? false : true)
    const hiddenSidebar = () => {
      activeSlider.value = false
    }
    const checkProhibitedRoles = (list: any) => {
      if (list.length < 1) {
        return true
      }
      const val = list.indexOf(store.state?.userProfile?.RolesName)
      return val < 0
    }

    onMounted(() => {
      // store.dispatch('fetchProfile')
      isLoaded.value = true
    })

    return {
      activeSlider,
      hiddenSidebar,
      store,
      env,
      isMobile,
      // checkProhibitedRoles,
      version,
      isLoaded
    }
  }
})
