
  import { defineComponent, ref, toRefs } from 'vue'
  import { Validation } from '@vuelidate/core'
  import FormInput from '@/views/components/form/FormInput.vue'
  import FormInputNumber from '@/views/components/form/FormInputNumber.vue'
  import FormDatePicker from '@/views/components/form/FormDatePicker.vue'
  import FormTextarea from '@/views/components/form/FormTextarea.vue'
  import { IOrderForm } from './formModel'

  export default defineComponent({
    components: {
      FormInput,
      FormInputNumber,
      FormDatePicker,
      FormTextarea,
    },
    props: {
      formData: {
        type: Object as () => IOrderForm,
        required: true,
      },
      v$: {
        type: Object as () => Validation,
        required: true,
      }
    },
    setup(props) {
      const localFormData = ref({ ...props.formData })
      const shippingTypeOptions = ref(['AA', 'AB'])

      return { localFormData, ...toRefs(props.v$), shippingTypeOptions }
    },
  })
