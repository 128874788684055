
  import { defineComponent, computed, ref } from 'vue'
  import VerticalHeading from '@/views/components/heading/VerticalHeading.vue'
  import RouteLine from '@/views/shared-components/RouteLine.vue'
  import { IOrderForm } from './formModel'

  export default defineComponent({
    components: {
      VerticalHeading,
      RouteLine
    },
    props: {
      formData: {
        type: Object as () => IOrderForm,
        required: true
      },
      environment: {
        type: String, 
        required: true, 
      }
    },
    setup(props) {
      const isProduction = computed(() => props.environment === 'production')
      return {
        isProduction
      }
    },
  })
